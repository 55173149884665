.App {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 50px;
}


.App-logo {
  pointer-events: none;
  opacity: 0.6;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.body {
  display: flex;
  width: 100vw;
  flex-direction: column;
  max-width: 600px;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
}

.donate-window {
  margin-top: 20px;
  width: 90%;
  background-color: #343943;
  text-align: center;
  border-radius: 4px;
  font-weight: 600;
}

.donate-header {
  background-color: #4fd951;
  border-radius: 4px 4px 0 0;
  padding: 5px;
  background: linear-gradient(to left,#4fd951 0%,#e6c20d 100%);
}

.donate-header-2 {
  padding: 20px;
  background-color: #3b424f;
}

.donate-header-input {
  width: 90%;
  height: 35px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  color: aliceblue;
  background-color: #3b424f;
  border-radius: 4px;
  border: 0px;
  padding: 10px;
}

.donate-header-input:focus {
  background-color: #3b424f;
  border: 0px;
}

.donate-button {
  padding: 10px;
  width: 100%;
  border: 0px;
  background: linear-gradient(to left,#2187ff 0%,#a70de6 100%);
  cursor: pointer;
  z-index: 10;
  font-size: 24px;
  color: aliceblue;
  font-weight: 600;
  margin-top: 5px;
}

.donate-button-qiwi {
  display: flex;
  width: 100%;
  height: 50px;
  border: 0px;
  border-radius: 0 0 4px 4px;
  background: linear-gradient(to left, rgb(255, 140, 0) 0%,rgb(245, 66, 90) 100%);
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 5px;
  font-weight: 600;
  font-size: 24px;
  color: aliceblue;
}

.donate-button-keksik {
  display: flex;
  width: 100%;
  height: 50px;
  border: 0px;
  border-radius: 4px 4px 0 0;
  background: linear-gradient(to left, #529ceb 0%,#516aeb 100%);
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 5px;
  font-weight: 600;
  font-size: 24px;
  color: aliceblue;
}

.donate-button-error {
  display: flex;
  width: 100%;
  height: 50px;
  border: 0px;
  border-radius: 0 0 4px 4px;
  background: linear-gradient(to left, rgb(255, 0, 0) 0%,rgb(245, 66, 66) 100%);
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 5px;
}

.donate-button-info {
  display: flex;
  width: 100%;
  height: 50px;
  border: 0px;
  border-radius: 0 0 4px 4px;
  background: linear-gradient(to left, rgb(22, 64, 255) 0%,rgb(167, 66, 245) 100%);
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 5px;
}

.donate-button-qiwi:disabled {
  opacity: 0.5;
}

.donate-button-keksik:disabled {
  opacity: 0.5;
}

.donate-button-qiwi > img {
  height: 60%;
  padding: 5px;
  opacity: 0.9;
  margin-right: 20px;
}

.donate-button:disabled {
  opacity: 0.5;
}

.footer {
  color: rgb(72, 91, 108);
  font-size: 14px;
}

.donate-header-input-header {
  margin-top: 10px;
  color: #7c828d;
}

.info {
  display: flex;
  font-size: 14px;
  margin-top: 10px;
}

.info > a {
  margin: 5px;
  color: #2187ff;
}

.shop-info {
  font-size: 14px;
  margin-top: 10px;
  color: #7c828d;
  text-align: justify;
  padding: 5px;
}

.shop-info-header {
  margin-top: 10px;
}

.shop-info-button {
  color: #6884b9;
  cursor: pointer;
  margin-top: 10px;
  text-align: center;
  font-size: 18px;
}

.card-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-image > img {
  width: 50%;
  margin-top: 10px;
}


.card-image-wallets {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-image-wallets > img {
  width: 25%;
  margin: 10px;
  margin-top: 10px;
}


.shop-fields > h1 {
  margin: 10px;
  font-size: 20px;
}

.shop-fields > div {
  display: grid;
  grid-template-columns: 1fr 100px 20px;
  margin: 10px;
  font-size: 18px;
  text-align: left;
}

.shop-fields > div > p {
  margin: 0;
}

.shop-fields > div > span {
text-align: 12px;
}

.footer-warn {
  font-size: 14px;
  margin: 5px;
  color: antiquewhite;
}

.PayFooter {
  font-size: 12px;
}